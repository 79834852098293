import React, { createContext, useContext, useRef, ReactNode, MutableRefObject, FC } from 'react';

interface SignUpContextType {
  signUpViewRef: MutableRefObject<HTMLInputElement | null>;
}

const SignUpContext = createContext<SignUpContextType | undefined>(undefined);

export const SignUpProvider: FC = ({ children }) =>  {
  const signUpViewRef = useRef<HTMLInputElement>(null);

  return (
    <SignUpContext.Provider value={{ signUpViewRef }}>
      {children}
    </SignUpContext.Provider>
  );
};

export const useSignUp = (): SignUpContextType => {
  const context = useContext(SignUpContext);
  if (!context) {
    throw new Error('useSignUp must be used within a SignUpProvider');
  }
  return context;
};
